<template>
  <div id="container">
    <h3 v-if="uniqueUser">Cette mission est à toi si tu prends tous les créneaux 🤪</h3>
    <h3 v-else-if="slotsPerMonths.length > 0">Choisis les créneaux que tu souhaites réserver :</h3>
    <div v-else-if="alreadyBookSlotCount === 0">
      <h3>Tu dois nous appeler pour prendre cette mission 😁</h3>
      <ion-button>
        <a :href="phoneAlloopharma" class="cWhite">
          Appeler Alloopharma
        </a>
      </ion-button>
    </div>

    <div v-for="slot in slotsPerMonths" :key="slot.id" class="slot-line" @click="save(slot)">
      <div style="display: flex; flex-direction: row">
        <div>
          <h4>{{slot.date}}</h4>
          <div class="text-small">
            <span>{{ slot.start }} - {{ slot.end }} </span>
            &nbsp;
            <span v-if="slot.isFlexible">(flexible) &nbsp;</span>
            <span class="blue"><ion-icon class="ion-item-center" :icon="cafeOutline"></ion-icon> {{ slot.hourRest}}h</span>
            <span style="margin-left:3px" class="blue" v-if="slot.ownerStatus === 'present'">&nbsp;👨‍⚕️Titulaire présent</span>
            <span style="margin-left:3px" class="red" v-if="slot.ownerStatus === 'replacement'">&nbsp;👨‍⚕️Titulaire absent</span>
          </div>
        </div>

      </div>
      <div style="display: flex; flex-direction: row">
        <ion-text :color="slot.isClicked? 'success' : 'danger'" style="margin-right: 1rem" v-if="slot.increase > 0">
          <h4>
            {{ slot.increase * slot.hourWorked }}€🎁
          </h4>
        </ion-text>
        <ion-text :color="slot.color">
          <h4>
            +{{ calculatedSalary(slot)}}€
          </h4>
        </ion-text>
      </div>

    </div>

    <ion-text v-for="slotsPerMonth in slotsPerMonths" :key="slotsPerMonth.id">
      <h3 class="month">{{ slotsPerMonth.dateYearMonth }}</h3>
      <ion-item v-for="day in slotsPerMonth.days" :key="day.id">
        <ion-text class="day" slot="start">{{ day.dayName }} {{ day.dayNum }} {{ }}</ion-text>
        <div slot="start" v-for="slot in day.slotsDay" :key="slot.id">
          <ion-item :color="slot.color" @click="save(slot)" lines="none" class="itemSlot">
            <span>{{ slot.start }} -> {{ slot.end }}</span>
             <span class="cBlack">{{ slot.hourRest }}h</span>
          </ion-item>
        </div>
      </ion-item>
    </ion-text>
    <ion-text v-if="slotBooked.length > 0">
      <h1>Si tu valides, tu vas gagner: <ion-text color="success">{{ cTotalPrice }}€</ion-text></h1>
    </ion-text>
    <ion-button v-if="slotBooked.length > 0" v-on:click="validate()" style="margin-bottom: 5rem;">
      Accepter
    </ion-button>
  </div>
</template>

<script>
import { Dialog } from '@capacitor/dialog';
import store from "@/store";
import { cafeOutline} from 'ionicons/icons';
import {IonText, IonItem, IonButton, IonIcon, alertController} from "@ionic/vue";
import { bookShifts, updateUnavailabilities } from "@/services/api";

export default {
  name: "SlotMission",
  components: {
    IonText,
    IonItem,
    IonIcon,
    IonButton
  },
  setup() {
    return {
      cafeOutline
    }
  },
  props: {
    slotsMonths: { type: Array, required: true },
    uniqueUser: { type: Boolean, required: true },
    alreadyBookSlotCount: { type: Number, required: true}
  },
  data() {
    return {
      colorClicked: "success",
      backgroundColorClicked: "#E5E5E566;",
      totalPrice: 0,
      slotBooked: [],
      slotsPerMonths: [],
      isAllSelected: false,
      state: {
        isConfirmed: null
      }
    };
  },
  mounted() {
    this.slotsPerMonths = this.mSlots()
  },
  computed: {
    cTotalPrice() {
      return this.totalPrice.toFixed(2)
    },
    phoneAlloopharma() {
      return `tel:${store.getters.phoneAlloopharma}`;
    },
  },
  methods: {
    mSlots() {
      this.slotsMonths.forEach(slot => {
        if (slot.canTakeIfSuggest) {
          slot.color = 'medium';
        } else {
          slot.color = null;
        }
        slot.isClicked = false
      });
      return this.slotsMonths
    },

    async showAskToConfirmToAvailable (){
      let message = ""
      if (!this.uniqueUser)
        message = "Oups, il semblerait que tu sois indisponible, mais tu peux changer d'avis 😉"
      else
        message = "Rends toi disponible sur tous les créneaux pour prendre cette mission 😎"
      const confirmRes = await Dialog.confirm({
        title: "Confirmer",
        message: message
      });
      return confirmRes.value;
    },
    getMatchDateUnvailabilitiesSlots() {
      const allDateSlots = []
      this.slotsPerMonths.forEach(slot => {
        if (slot.canTakeIfSuggest)
          allDateSlots.push(slot.date)
      });
      return allDateSlots
    },
    async selectAllSlots(salary) {
      const allDatesSlots = this.getMatchDateUnvailabilitiesSlots()
      if(allDatesSlots.length > 0) {
        let isBecomeAvailable = false
        await this.askToConfirmToAvailable(allDatesSlots).then((response) => {
          isBecomeAvailable = response
        })
        if (!isBecomeAvailable) return
      }
      this.setTrueForCanTakeIfSuggestForAllSlot();
      this.isAllSelected = !this.isAllSelected
      this.slotsPerMonths.forEach(slot => {
        if (this.isAllSelected) {
          this.selectOneSlot(slot, salary)
        } else {
          this.unselectOneSlot(slot, salary)
        }
      });
    },
    setTrueForCanTakeIfSuggestForAllSlot() {
      this.slotsPerMonths.forEach(slot => {
        slot.canTakeIfSuggest = false;
      });
    },
    async askToConfirmToAvailable(slots) {
      let isConfirmed = false
      await this.showAskToConfirmToAvailable().then((response) => {
        isConfirmed = response
      })
      if (!isConfirmed) return isConfirmed
      const newUnavailabilities = store.getters.unavailabilities

      slots.forEach(slot => {
        const dayEqual = (element) => element == slot.date;
        const indexDayFinded = newUnavailabilities.findIndex(dayEqual)
        newUnavailabilities.splice(indexDayFinded, 1);
      });
      await updateUnavailabilities(newUnavailabilities).then(() => {
        isConfirmed = true
      }).catch(() => {
        isConfirmed = false
      });
      return isConfirmed
    },

    calculatedSalary(slot) {
      return Math.floor(Number(slot.pricing) * Number(slot.hourWorked) * 100) / 100;
    },
    selectOneSlot(slot, salary) {
      this.totalPrice += (salary + slot.increase * slot.hourWorked);
      slot.isClicked = true;
      slot.color = this.colorClicked;
      slot.backgroundColor = this.backgroundColorClicked;
      this.slotBooked.push(slot.id);
    },
    unselectOneSlot(slot, salary) {
      this.totalPrice -= (salary + slot.increase * slot.hourWorked);
      slot.isClicked = false;
      slot.color = null
      slot.backgroundColor = null;
      const index = this.slotBooked.indexOf(slot.id);
      if (index > -1) {
        this.slotBooked.splice(index, 1);
      }
    },
    async save(slot) {
      const salary = this.calculatedSalary(slot)
      if (this.uniqueUser) {
        this.selectAllSlots(salary)
        return
      }
      if (slot.canTakeIfSuggest) {
        let isConfirm = false
        this.askToConfirmToAvailable([slot]).then((response) => {
          isConfirm = response
        })
        if (!isConfirm) return
        slot.canTakeIfSuggest = false
      }
      if (!slot.isClicked) {
        this.selectOneSlot(slot, salary)
      } else {
        this.unselectOneSlot(slot, salary)
      }
    },

    validate() {
      if (store.getters.isProfilAccepted) {
        this.showAlertMessageProfilNotConfirmed()
        return
      }
      if (this.showConfirmWantTakeSlot()) {
        bookShifts(this.slotBooked).then((response) => {
          const ourShifts = response.shifts.filter((response) => this.slotBooked.includes(response.id));
          if (ourShifts.length === this.slotBooked.length) {
            this.showConfirmationAlert().then()
          } else {
            Dialog.alert({
              title: "IMPOSSIBLE",
              message: "Ce(s) créneaux ont déjà été pris par quelqu'un, veuillez sélectionner à nouveau parmi ceux disponibles"
            }).then(() => document.location.reload());
          }
        })
      }
    },
    async showConfirmWantTakeSlot() {
      const confirmRes = await Dialog.confirm({
        title: "Confirmer",
        message: "Tu es bien sûr de vouloir réserver ces créneaux ?"
      });
      return confirmRes;
    },
    async showAlertMessageProfilNotConfirmed() {
      let message = "Tu ne peux pas prendre de créneaux. "
      if (!store.getters.user.isConfirmed) {
        message += "Tu dois d'abord confirmer ton numéro de téléphone en te rendant sur le tableau de bord et en cliquant sur le bouton prévu à cet effet."
      } else if (store.getters.user.acceptationStatus === "En attente de complétion du profil") {
        message += "Tu dois d'avord compléter ton profil en te rendant sur le tableau de bord et en cliquant sur le bouton prévu à cet effet."
      } else if (!store.getters.user.isCalendlyMeetTaken) {
        message += "Tu dois d'abord passer ton entrevues avec nous en te rendant sur le tableau de bord et en cliqueant sur le bouton prévu à cet effet."
      } else if (store.getters.user.acceptationStatus == "En attente de validation par Alloopharma") {
        const num = store.getters.phoneAlloopharma
        message += `Ton profil est en attente d'acceptation par nos soins. Si tu as manqué notre rendez-vous, appelles nous vite au ${num}`
      }

      await Dialog.alert({
        title: "IMPOSSIBLE",
        message: message
      });
    },
    async showConfirmationAlert() {
      const alert = await alertController.create({
        header: "Ta mission est bien réservée ✅",
        message: "Nous vérifions la disponibilité de la mission auprès du titulaire.<br/>" +
            "Tu recevras ton contrat par mail dans moins de 12 h.<br/>" +
            "A nous renvoyer signé ☺️",
        buttons: ['Parfait'],
      });

      alert.onWillDismiss().then(() => {
        document.location.reload();
      })

      await alert.present();
    }
  }
};
</script>

<style scoped>
.itemSlot {
  border-radius: 10px;
  --padding-left: 0px;
}
.day {
  color: rgb(74, 74, 74)
}

.slot-line {
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: .5rem;
  border-bottom: 1px solid lightgrey;
  max-width: 50rem;

}

.slot-line:hover {
  background-color: #E5E5E566;
}


.text-small {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.blue {
  color: #014493;
  display: inline-flex;
  align-items: center;
  background-color: #009A954D;
  padding: 2px;
}

.red {
  color: #ca0101;
  display: inline-flex;
  align-items: center;
  background-color: #009A954D;
  padding: 2px;
}

.month {
  color:rgb(242, 242, 242);
  background-color: rgb(146, 96, 200);
}
</style>

<template>
  <ion-page>
    <ion-content>
      <div class="back-button">
        <ion-button @click="$router.go(-1)" >
          <ion-icon
              :icon="chevronBack"
          />
        </ion-button>
      </div>
      <div v-if="hasLoad">
        <ion-text>
          <h1>
            {{ mission.title }}
            {{ POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES[mission.distinctSignsSensibility] }}
          </h1>
        </ion-text>

        <ion-text v-if="mission.description || mission.pharmacyDescription">
          <p class="description">
            <span v-html="mission.description"/> <br/>
            <span v-html="mission.pharmacyDescription"/>
          </p>
        </ion-text>
        <div v-else style="margin: 30px 0 20px 0"/>
        <div style="width: 100%; margin-bottom: 2rem;">
          <div class="pills">
            <div class="pill">
              <ion-icon
                  class="icon"
                  :icon="medkitOutline"
              />
              <ion-text>
                <span style="text-decoration: underline">Logiciel</span>&nbsp;:
                {{ mission.software || "Non renseigné" }}
              </ion-text>
            </div>

            <div class="pill" v-if="mission.ambiance !== null">
              <ion-text>
                <span style="text-decoration: underline">Ambiance</span>&nbsp;:&nbsp;
              </ion-text>
              <ion-icon :icon="star" class="icon" v-for="n in mission.ambiance" :key="n"/>
              <ion-icon :icon="starOutline" class="icon" v-for="n in 5 - mission.ambiance" :key="n" />
            </div>

            <ion-text class="pill" v-if="mission.parking">
              <span v-if="mission.parking === 'payable'">🅿️💸 Parking payant</span>
              <span v-else-if="mission.parking === 'free'">🅿️ Parking gratuit</span>
              <span v-else>❌ Pas de parking</span>
            </ion-text>

            <ion-text class="pill" v-if="mission.skillNeeded">
              <span v-if="mission.skillNeeded === 'all'">🟢Tout niveau accepté</span>
              <span v-else>🟠Niveau confirmé uniquement</span>
            </ion-text>

            <ion-text class="pill" v-if="mission.transport">
              <span v-if="mission.transport === 'car'">🚘Voiture fortement conseillé</span>
              <span v-else-if="mission.transport === 'transport'">🚌Transports en commun fortement conseillé</span>
              <span v-else>🚘/🚌Voiture et Transport communs</span>
            </ion-text>
          </div>
        </div>

        <div class="map-section">
          <ion-text>
            <p>
              {{ mission.tookSlots.length > 0 ? mission.address : mission.displayAddress }}
              (à {{ mission.distance }}km)
            </p>
          </ion-text>
          <div id="map" style="width: 400px; height: 400px"/>
        </div>
        <div v-if="mission.tookSlots.length > 0" style="margin-bottom: 4rem;">
          <h5 style="color: black">
            Avec les créneaux que tu as déjà pris, cette mission te rapportera :
            <ion-text color="success">{{ countSalary(mission.tookSlots) }}€</ion-text>
            (salaire estimé)
          </h5>

          <ion-text>
            <h4 style="text-decoration: underline">Voici {{mission.tookSlots.length === 1 ? 'ton crénau' : 'tes créneaux'}} :</h4>
          </ion-text>

          <div v-for="slot in mission.tookSlots" :key="slot.id" class="slot-line" >
            <h4>{{slot.date}} de {{ slot.start }} à {{ slot.end }} {{ slot.isFlexible ? '(flexible)' : ''}}</h4>
          </div>
        </div>
        <div style="margin-bottom: 4rem;" >
          <SlotMission
              @addToSalary="addToSalary"
              :slotsMonths="mission.slotsMonths"
              :already-book-slot-count="mission.tookSlots.length"
              :uniqueUser="mission.uniqueUser"
          >
          </SlotMission>
        </div>
      </div>
      <div v-else>
        <ion-text class="ion-text-center">
          <h1>Chargement en cours... ⏳</h1>
        </ion-text>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import store, {POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES} from "@/store";
import { Loader } from '@googlemaps/js-api-loader'
import {IonPage, IonText, IonContent, IonIcon, IonButton} from "@ionic/vue";
import {baseURL, getMissionInfo} from "@/services/api";
import {
  calendarOutline,
  documentText,
  informationCircleOutline,
  medkitOutline,
  navigateOutline,
  starOutline,
    star,
    chevronBack,
} from "ionicons/icons";
import SlotMission from "@/components/SlotMission";

const KEY = 'AIzaSyA8qMxbDPD837BKlodEN1mIIQPpfPG8hqw';

export default {
  name: "Mission",
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonText,
    SlotMission,
    IonButton,
  },
  data() {
    return {
      hasLoad: false,
      triggerFalseSeeSlots: 0,
      mission: {},
      dSalary: this.salary,
    };
  },
  setup() {
    return {
      navigateOutline,
      medkitOutline,
      documentText,
      informationCircleOutline,
      calendarOutline,
      starOutline,
      star,
      chevronBack,
    };
  },

  ionViewDidEnter() {
    const { id } = store.getters.currentMission;
    getMissionInfo(id).then((response) => {
      this.mission = response;
      this.hasLoad = true;
      this.createMap().then();
    })
  },
  ionViewWillLeave() {
    this.hasLoad = false;
  },
  computed: {
    POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES() {
      return POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES
    },
    calculatedSalary() {
      if (this.mission.slotsMonths === null) {
        return this.salary;
      } else if (this.mission.slotsMonths.length === 0) {
        return '∞'
      }
      return this.countSalary(this.mission.slotsMonths)
    },
  },

  methods: {
    countSalary(slots) {
      let workedHours = 0;
      let increase = 0;
      slots.forEach(slot => {
        workedHours += slot.hourWorked * slot.pricing;
        increase += slot.hourWorked * slot.increase;
      });
      return Math.floor(workedHours * 100) / 100 + increase;
    },
    addToSalary(salarySlot) {
      this.dSalary += salarySlot;
    },

    async createMap() {
      const loader = new Loader({ apiKey: KEY })
      await loader.load();
      const center = { lat: this.mission.latitude, lng: this.mission.longitude };
      const google = window.google;
      const map = new google.maps.Map(document.getElementById('map'), {
        center,
        zoom: 14,
        disableDefaultUI: true,
        mapId: '301fae7e73c874ae',
        mapTypeId: "roadmap",
      });
      if (this.mission.tookSlots.length === 0) {
        new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map,
          center,
          radius: 500,
        });
      } else {
        const pharmacyMarker = {
          title: this.pharmacyName,
          position: center,
          url: baseURL + '/constants/pharmacy'
        };
        [store.getters.userMarker, pharmacyMarker].forEach((marker) => {
          // eslint-disable-next-line no-undef
          new google.maps.Marker({
            ...marker,
            icon: {
              url: marker.url,
              // eslint-disable-next-line no-undef
              scaledSize: new google.maps.Size(40, 40), // size
              // eslint-disable-next-line no-undef
              origin: new google.maps.Point(0,0), // origin
            },
            map: map,
          });
        })
      }
    }
  }
}

</script>

<style scoped>
.pill {
  padding: .3rem 1rem;
  border-radius: 23px;
  border: 2px solid var(--ion-color-primary);
  color: #454545;
  display: inline-flex;
  align-items: center;
  height: 34px;
  margin-right: 1rem;
  margin-bottom: .5rem;
  margin-left: 1rem;
}


.pill:hover {
  border: 2px solid var(--ion-color-tertiary-tint);
}

.icon {
  color: #d56498;
  margin-right: 6px;
}

.pills {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.map-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.description {
  width: 50%;
  color: var(--ion-color-secondary);
  border: 1px solid #80808042;
  border-radius: 13px;
  padding: .5rem;
  margin: auto auto 2rem;
}

.back-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 100%;
  text-align: left;
}

@media screen and (max-device-width:640px), screen and (max-width:640px) {
  .description {
    width: 100%;
  }

  .back-button {
    position: relative;
  }

  .pills {
    flex-direction: column;
  }

}

</style>
